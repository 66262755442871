<template>
  <div class="flex flex-col overflow-y-hidden">
    <!-- Title: 'Individual Search Sessions' -->
    <div class="flex justify-between content-center items-center">
      <section-title> Individual Search Sessions </section-title>
      <button class="w-6 h-6 mr-2" @click="onTooltipOpen()" @mouseenter="onTooltipHover()" @mouseleave="onTooltipHoverLeave()">
        <question-mark />
      </button>
    </div>
    <div class="relative">
      <div v-if="showTooltip || tooltipClicked" class="absolute z-20
        right-6            cursor-pointer
        bg-white
        drop-shadow-sm
        rounded-md
        border-gray-500 border
        px-2
        py-2" @click="onTooltipClose()">
        <ul>
          <li><span class="font-bold">CTR@5</span>: 전체 쿼리 중, 사용자가 1-5순위 검색 결과를 클릭한 쿼리의 비율</li>
          <li><span class="font-bold">MeanRR</span>: 사용자가 클릭한 검색 결과들의 순위 역수의 평균 </li>
          <li><span class="font-bold">Abandonment rate</span>: 전체 쿼리 중, 사용자가 어떤 결과도 클릭하지 않은 쿼리의 비율</li>
          <li><span class="font-bold">Reformulation rate</span>: 전체 쿼리 중, 이전 쿼리와 유사하여 RefinedQuery로 판정된 쿼리의 비율</li>
          <li><span class="font-bold">mAP</span>: 검색 결과의 순위와 사용자가 클릭한 문서 순위의 일치 정도</li>
          <li><span class="font-bold">NDCG</span>: 가중치를 포함한 검색 결과의 순위와 사용자가 클릭한 문서 순위의 일치 정도</li>
        </ul>
      </div>
    </div>
    <div class="flex flex-col my-4 mx-2 overflow-y-hidden">
      <!-- The dropdown -->
      <div class="flex flex-none flex-row-reverse justify-between items-center">
        <div v-if="sessions !== null" class="flex items-center m-1">
          <div class="mx-2 relative">
            <medium-title class="relative">
              Sort By (worst to best):
            </medium-title>
          </div>
          <sort-by-dropdown />
        </div>
        <div v-if="sessions !== null">
          <div>{{sessions.length}} sessions presented. </div>
          <div v-if="currentKeywordCluster !== null">
            Current query cluster: {{currentKeywordCluster.topFiveKeywords.join(" / ")}}
          </div>
        </div>
      </div>
      <!-- The query list -->
      <div class="h-full overflow-y-auto flex flex-auto flex-col mt-4">
        <session-item
          v-for="session in sessions"
          :key="session.id"
          class="my-2"
          v-bind="{
            session: session,
          }"/>
      </div>
      <div v-if="sessions === null" class="text-center">
        <medium-title>
          Start by selecting a cluster from the left. 
        </medium-title>
      </div>
      <div v-else-if="sessions.length === 0" class="text-center">
        <medium-title>
          No sessions found. Try different clusters.
        </medium-title>
      </div>
    </div>
  </div>
</template>

<script>
import SectionTitle from "@/components/Common/SectionTitle.vue";
import MediumTitle from "../Common/MediumTitle.vue";
import SortByDropdown from "@/components/QueryList/SortByDropdown.vue";
import SessionItem from "@/components/QueryList/SessionItem.vue";
import { useGlobalStore } from "@/stores/globalStoreAgent.js";
import { computed, provide, ref, inject } from "vue";
import QuestionMark from '../Common/Icons/QuestionMark.vue'

export default {
  name: "QuerySelection",
  components: {
    SectionTitle,
    MediumTitle,
    SortByDropdown,
    SessionItem,
    QuestionMark
  },
  setup() {
    // The currently active sortByOption

    const sortByOption = ref(window.globalVars.METRICS[0]);
    // Inject the methods to manipulate the state of the global store
    const store = useGlobalStore();
    // Current interaction state (which panel is open, which metric is chosen)
    const interactionState = computed(() => store.getInteractionState.value);
    // Updates the interaction state
    const setInteractionState = store.setInteractionState;
    // List of sessions, sorted by the current sortByOption from worst to best
    const sessions = computed(() => {
      const sessions = store.getSessions.value;
      if (sessions !== null) {
        return Object.values(sessions).sort((a, b) => {
          const aMetricVal = a.metricValues[sortByOption.value];
          const bMetricVal = b.metricValues[sortByOption.value];
          // sort bad to good
          return window.globalVars.IS_METRIC_GOODNESS_DIRECT[sortByOption.value]
            ? aMetricVal - bMetricVal
            : bMetricVal - aMetricVal;
        });
      }
      return null;
    });
    const createLog = inject('createLog')
    // When the sortByOption changes
    const onSortByOptionChange = (option) => {
      sortByOption.value = option;
    };

    const keywordClusters = store.getKeywordClusters.value

    const currentKeywordCluster = computed(() => {
      const currentKeywordClusterId = interactionState.value.chosenKeywordClusterId
      if (currentKeywordClusterId === null) {
        return null
      }
      const currentCluster = Object.values(keywordClusters).find(k => k.id === currentKeywordClusterId)
      return currentCluster
    })

    // Provide 'parentCallFunction', 'mainOption' and 'allOptions' to the dropdown component
    // It actually provides those to all the children components, but they are used only in the dropdown 
    provide("parentCallFunction", onSortByOptionChange);
    provide("mainOption", sortByOption);
    provide("allOptions", window.globalVars.METRICS);

    return {
      interactionState,
      setInteractionState,
      sessions,
      currentKeywordCluster,
      keywordClusters,
      createLog
    };
  },
  methods: {
    onTooltipHover: function () {
      this.createLog('hoverMetricTooltip')
      this.showTooltip = true;
    },
    onTooltipHoverLeave: function () {
      this.createLog('hoverLeaveMetricTooltip')
      this.showTooltip = false;
    },
    onTooltipOpen: function () {
      this.createLog('openMetricTooltip')
      this.tooltipClicked = true;
      this.showTooltip = false
    },
    onTooltipClose: function () {
      this.createLog('closeMetricTooltip')
      this.tooltipClicked = false;
    },
  },
  data: function () {
    return {
      showTooltip: false,
      tooltipClicked: false
    }
  }
};
</script>

<style>
</style>
